import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  padding: 4px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin: 10px 4px;
  cursor: pointer;
  color: ${(props) => (props.active ? '#ffffff' : 'inherit')}
  background-color: ${(props) => (props.active ? '#81c342' : '#ffffff')}
  border-color: ${(props) => (props.active ? '#81c342' : '#e6e6e6')}

  &:hover {
    color: #ffffff;
    background-color: #81c342;
    border-color: #81c342
  }
`;

const Container = styled.div`
  text-align: center;
`;

const Pagination = ({ currentPage, pageCount, updatePage }) => { // 815, 817
  let startIndex = Math.max(0, currentPage - 2); // 813
  const endIndex = Math.min(pageCount, startIndex + 5); // 817

  if (endIndex - startIndex < 5) {
    startIndex = Math.max(0, endIndex - 5);
  }

  const showFirst = currentPage !== 0;
  const showLast = currentPage !== pageCount;
  const hasFirstIndexGap = startIndex !== 0;
  const hasLastIndexGap = endIndex !== pageCount;
  const nextPage = currentPage + 1;
  const prevPage = currentPage - 1;

  const pages = [];

  for (let i = startIndex; i <= endIndex; i += 1) {
    pages.push(i);
  }

  return (
    <Container>
      {showFirst && <Button onClick={() => updatePage(0)}>&laquo; First</Button>}
      {showFirst && <Button onClick={() => updatePage(prevPage)}>‹ Previous</Button>}
      {hasFirstIndexGap && <span>...</span>}

      {pages.map((page) => (
        <Button
          key={page}
          active={page === currentPage}
          onClick={() => updatePage(page)}
        >
          {page + 1}
        </Button>
      ))}

      {hasLastIndexGap && <span>...</span>}
      {showLast && <Button onClick={() => updatePage(nextPage)}>Next ›</Button>}
      {showLast && <Button onClick={() => updatePage(pageCount)}>Last &raquo;</Button>}
    </Container>
  );
};

Pagination.defaultProps = {
  currentPage: 0,
  pageCount: 0
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  updatePage: PropTypes.func.isRequired
};

export default Pagination;
