import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import SearchFacilities from "components/SearchFacilities";
import ResultsFacilities from "components/ResultsFacilities";
import GetFilters from "config/filtersFacility";
import ENV from "config/env";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { FacilitySearch, FacilityAutocomplete } from "api";
import styled from "styled-components";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { resetGlobalSearch, updateGlobalSearch } from "api/GlobalSearch";

const MobileActionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  background-color: #e4f0f8;
  border: 2px solid #006298;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 14px 16px;
  cursor: pointer;
  margin: 10px;
  display: none;

  &:hover {
    background-color: #ffffff;
  }

  @media (max-width: 640px) {
    display: initial;
  }
`;

const {
  actions: { requestFacilitySearch },
} = FacilitySearch;
const {
  actions: { resetFacilityAutocomplete },
} = FacilityAutocomplete;

const HomeFacilities = (props) => {
  const {
    history: { push },
    location: { search: qs },
    FacilitySearch: { facet },
  } = props;

  const query = queryString.parse(qs);

  const defaultState = {
    keyword: "",
    location: "",
    distance: "5",
    cin: "AHPN-WF,AHPN-CF",
    filters: {},
  };

  const {
    keyword: queryKeyword,
    location: queryLocation,
    distance: queryDistance,
    cin: queryCin,
    page: queryPage,
    ...queryFilters
  } = query;

  const [keyword, setKeyword] = useState(queryKeyword || defaultState.keyword);
  const [location, setLocation] = useState(
    queryLocation || defaultState.location
  );
  const [distance, setDistance] = useState(
    queryDistance || defaultState.distance
  );
  const [cin, setCin] = useState(queryCin || defaultState.cin);
  const [filters, setFilters] = useState(queryFilters || defaultState.filters);
  const [resetAll, setResetAll] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [mapsInfo, setMapsInfo] = useState({});
  const dispatch = useDispatch();

  const [page, setPage] = useState(
    (queryPage && Number.parseInt(queryPage, 10)) || 1
  );
  const [searching, setSearching] = useState(true);

  const [showSearch, setShowSearch] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const toggleShowSearch = () => {
    setShowSearch(!showSearch);
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const hasKeyword = !!query.keyword;
  const availableFilters = GetFilters(facet, !hasKeyword);

  const getQueries = () => {
    if (resetAll) {
      setFilters(defaultState.filters);
      setResetAll(false);
      return {};
    }

    setResetAll(false);

    if (
      keyword === defaultState.keyword &&
      location === defaultState.location &&
      distance === defaultState.distance &&
      cin === defaultState.cin &&
      filters === defaultState.filters
    ) {
      return {};
    }

    return {
      keyword,
      location,
      distance,
      cin,
      page,
      ...filters,
    };
  };

  const performSearch = async () => {
    if (searching) {
      const { requestFacilitySearch: makeFacilitySearchCall } = props;
      const { resetFacilityAutocomplete: makeResetFacilityAutocompleteCall } =
        props;

      makeResetFacilityAutocompleteCall();

      const normalizedFilters = () => {
        const nf = {};

        Object.keys(filters).forEach((f) => {
          switch (f) {
            case "services":
              nf.treatmentOffered = filters[f];
              break;
            default:
              nf[f] = filters[f];
              break;
          }
        });

        return nf;
      };

      const addressFilters = async () => {
        if (location) {
          try {
            const results = await geocodeByAddress(location);
            const latLng = await getLatLng(results[0]);
            dispatch(updateGlobalSearch(latLng));
            setMapsInfo({
              searchCenter: latLng,
              searchDistance: distance,
            });

            // eslint-disable-next-line no-undef
            var values = require("object.values");

            if (!Object.values) {
              values.shim();
            }

            return {
              location: Object.values(latLng).join(","),
              distance: distance || 5,
              includeMissingAddress: false,
              missingAddress: false,
            };
          } catch (error) {
            setLocation("");
            return {};
          }
        }
        // Default parameters passed to map to allow
        // results to be pinned when no search
        // location is specified.
        setMapsInfo({
          searchDistance: "1000",
          county: filters.county,
        });
        return {};
      };

      const systemFilters = ENV.getEnv("FILTERS") || {};

      const addrFilters = await addressFilters();

      const parameters = {
        keyword,
        network: cin,
        page: Math.max(0, page - 1),
        ...normalizedFilters(),
        ...systemFilters,
        ...addrFilters,
      };

      const queries = getQueries();

      if (
        (!queries.keyword || queries.keyword === defaultState.keyword) &&
        (!queries.location || queries.location === defaultState.location) &&
        (!queries.distance || queries.distance === defaultState.distance) &&
        (!queries.cin || queries.cin === defaultState.cin) &&
        !Object.keys(normalizedFilters()).length
      ) {
        setHasSearched(false);
      } else {
        setHasSearched(true);
      }

      push(`?${queryString.stringify(queries)}`);
      setResetAll(false);
      makeFacilitySearchCall(parameters);
      setSearching(false);
    }
  };

  const runEffect = () => {
    performSearch();
  };

  useEffect(runEffect, [searching]);

  const reset = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setKeyword(defaultState.keyword);
    setLocation(defaultState.location);
    setDistance(defaultState.distance);
    setCin(defaultState.cin);
    setFilters(defaultState.filters);
    setPage(1);
    dispatch(resetGlobalSearch());

    setResetAll(true);
    setSearching(true);
  };

  const updatePage = (pageNum) => {
    pageNum += 1;
    setPage(pageNum);

    setSearching(true);
  };

  const search = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFilters(defaultState.filters);

    updatePage(0);
  };

  const updateFilters = async (selectedFilters) => {
    await setFilters(selectedFilters);
    setPage(1);

    setSearching(true);
  };

  const updateState = (name, value) => {
    switch (name) {
      case "keyword":
        setKeyword(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "distance":
        setDistance(value);
        break;
      case "cin":
        setCin(value);
        break;
      default:
        break;
    }
  };

  const params = {
    keyword,
    distance,
    location,
    cin,
  };

  return (
    <>
      <Header />
      <MobileActionWrapper>
        <Button type="button" onClick={toggleShowSearch}>
          Search
        </Button>
        <Button type="button" onClick={toggleShowFilters}>
          Filter
        </Button>
      </MobileActionWrapper>
      <SearchFacilities
        search={search}
        reset={reset}
        updateState={updateState}
        params={params}
        showSearch={showSearch}
        hasSearched={hasSearched}
      />
      <ResultsFacilities
        updatePage={updatePage}
        filters={availableFilters}
        updateFilters={updateFilters}
        selectedFilters={filters}
        keyword={queryKeyword}
        showFilters={showFilters}
        hasSearched={hasSearched}
        mapsInfo={mapsInfo}
      />
    </>
  );
};

HomeFacilities.defaultProps = {
  search: "",
};

HomeFacilities.propTypes = {
  search: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  requestFacilitySearch: PropTypes.func.isRequired,
  resetFacilityAutocomplete: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  FacilitySearch: PropTypes.shape({
    meta: PropTypes.object,
    pagination: PropTypes.object,
    providers: PropTypes.array,
    facet: PropTypes.object,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  FacilitySearch: state.FacilitySearch,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestFacilitySearch,
      resetFacilityAutocomplete,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeFacilities)
);
