import * as Api from 'api';

var values = require('object.values');

const sagas = [];

if (!Object.values) {
	values.shim();
}

Object.values(Api).forEach((api) => {
  sagas.push(api.saga);
});

export default sagas;
