import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Checkbox } from 'components/Form';
import styled from 'styled-components';

const StyledFilters = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin: 0 auto;
  padding-right: 40px;
  box-sizing: border-box;

  @media(max-width:864px) {
    max-width: 100%;
    min-width: initial;
    padding-right: 0;
    margin-bottom: 40px;
    flex: 1 1 auto;
  }

  @media(max-width: 640px) {
    ${({ showFilters }) => !showFilters && 'display:none'}
  }
`;

const Heading = styled.h2`
  color: #81c342;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Label = styled.h3`
  color: #006298;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const FilterGroup = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const SelectStyle = `
  width: 100%;
  background-color: whitesmoke;
  border: 1px solid #666666;
`;

const Filters = (props) => {
  const {
    filters,
    updateFilters,
    selectedFilters: persistedFilters,
    showFilters
  } = props;

  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    setSelectedFilters(persistedFilters);
  }, [persistedFilters]);

  const getValue = (key) => {
    const findEnabledFilter = () => {
      const filter = filters.find((f) => f.key === key);

      if (filter) {
        const result = filter.options.find((val) => val.enabled);

        return result && result.value;
      }

      return false;
    };

    const value = selectedFilters[key] || findEnabledFilter() || '';

    return value.split(',');
  };

  const setFilter = (value, name, option) => {
    const sf = selectedFilters;

    const filter = filters.find((f) => f.key === name);

    if (filter && filter.type === 'checkbox') {
      const currFilter = (sf[name] && sf[name].split(',')) || [];
      const idx = currFilter.indexOf(option);
      if (idx !== -1 && !value) {
        currFilter.splice(idx, 1);
      } else {
        currFilter.push(option);
      }

      sf[name] = currFilter.join(',');
    } else {
      sf[name] = value;
    }

    setSelectedFilters(sf);
    updateFilters(sf);
  };

  const renderFacet = (facet) => (
    <FilterGroup key={facet.key}>
      <Label>{facet.label}</Label>
      {
        facet.type === 'select'
        && (
          <Select
            name={facet.key}
            value={getValue(facet.key)[0]}
            onChange={setFilter}
            styles={SelectStyle}
            options={facet.options}
          />
        )
      }
      {
        facet.type === 'checkbox'
        && (
          facet.options.map((option) => {
            const id = `${facet.key}-${option.value}`;

            return (
              <Checkbox
                key={id}
                facetKey={facet.key}
                value={option.value}
                text={option.text}
                onChange={setFilter}
                getValue={getValue}
              />
            );
          })
        )
      }
    </FilterGroup>
  );

  return (
    <StyledFilters showFilters={showFilters}>
      <Heading>Filter Results</Heading>
      {
        !!Object.keys(filters).length
        && (
          <div>
            {filters.map((filter) => renderFacet(filter))}
          </div>
        )
      }
    </StyledFilters>
  );
};

Filters.defaultProps = {
  selectedFilters: {},
  filters: []
};

Filters.propTypes = {
  selectedFilters: PropTypes.objectOf(PropTypes.string),
  filters: PropTypes.arrayOf(PropTypes.object),
  updateFilters: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired
};

export default Filters;
