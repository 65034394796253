import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAvatar = styled.div`
  display: flex;
  width: 150px;
  min-width: 150px;
  height: 150px;
  min-height: 150px;
  background-color: #006298;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 75px;
  line-height: 75px;
  background-image: ${(props) => `url(${props.photoURL})`};
  background-size: cover;

  @media(max-width: 640px) {
    width: 75px;
    min-width: 75px;
    height: 75px;
    min-height: 75px;
    font-size: 36px;
    line-height: 36px;
  }
`;

const Avatar = (props) => {
  const { photoURL, firstName, lastName } = props;

  if (photoURL) {
    return (<StyledAvatar photoURL={photoURL} />);
  }

  let initials = '';

  if (firstName && lastName) {
    initials = `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`.toUpperCase();
  } else if (firstName) {
    initials = `${firstName.slice(0, 1)}`.toUpperCase();
  }

  return (<StyledAvatar>{initials}</StyledAvatar>);
};

Avatar.defaultProps = {
  photoURL: null,
  firstName: '',
  lastName: ''
};

Avatar.propTypes = {
  photoURL: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string
};

export default Avatar;
