import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import Offices from 'components/Offices';
import styled from 'styled-components';

const ProviderCard = styled.div`
  border: 1px solid #cccccc;
  border-radius: 6px;
  margin-bottom: 40px;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 1140px) {
    flex-direction: column;
  }
`;

const Split = styled.div`
  width: ${(props) => props.width || '50%'};

  @media(max-width: 1140px) {
    width: 100%;
  }
`;

const Profile = styled.div`
  display: flex;
  margin-right: 20px;

  @media(max-width: 1140px) {
    margin-left: 0px;
    margin-bottom: 20px;
  }
`;

const BasicInfo = styled.div`
  margin-left: 40px;
  margin-top: 20px;

  @media(max-width: 640px) {
    margin-left: 20px;
    margin-top: 10px;
  }
`;

const Title = styled.p`
  color: #006298;
  // text-decoration: underline;
  font-size: 24px;
  font-weight: 400;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  margin-bottom: 16px;

  @media(max-width: 640px) {
    margin-bottom: 8px;
    font-size: 20px;
  }
`;

const Specialties = styled.p`
  font-size: 18px;

  @media(max-width: 640px) {
    font-size: 14px;
  }
`;

// const Network = styled.div`
//   background-color: #dcecf4;
//   display: flex;
//   justify-content: space-between;
//   width: initial;
//   padding: 20px;
//   border: 1px solid #25589d;
//   border-radius: 5px;
//   color: #25589d;
//   font-weight: 300;
// `;

const Provider = (props) => {
  const {
    provider: {
      firstName = '',
      lastName = '',
      displayName = '',
      fullName = '',
      photoURL = null,
      specialties,
      specialty,
      internalSpecialty,
      officeAddress,
      addresses
    }
  } = props;

  const showSpecialties = specialties || [specialty] || internalSpecialty || [];
  const offices = officeAddress || addresses || [];

  return (
    <ProviderCard>
      <Split width="60%">
        <Profile>
          <Avatar photoURL={photoURL} firstName={firstName} lastName={lastName} />
          <BasicInfo>
            <Title>{displayName || fullName}</Title>
            <Specialties>{showSpecialties.join(', ')}</Specialties>
          </BasicInfo>
        </Profile>
      </Split>
      <Split width="40%">
        <Offices offices={offices} />
        {/* <Network>
          <span>Provider Network:</span>
          <span>[TBD]</span>
        </Network> */}
      </Split>
    </ProviderCard>
  );
};

Provider.defaultProps = {};

Provider.propTypes = {
  provider: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Provider;
