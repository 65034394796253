import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const StyledLabel = styled.label`
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover > input ~ span,
  input:checked ~ span {
    border: 2px solid #1ca9e1;
    background-color: #1ca9e1;
    box-shadow: inset 2px 2px 0 0 white, inset -2px -2px 0 0 white;
  }

  input:checked ~ span:after {
    display: block;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #666666;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Checkbox = ({
  facetKey,
  value,
  text,
  getValue,
  onChange
}) => {
  const id = `${facetKey}-${value}`;
  const values = getValue(facetKey);
  const checked = values.indexOf(value) !== -1;

  const updateCheckbox = (event) => {
    const { target: { name, checked: isChecked } } = event;
    onChange(isChecked, name, value);
  };

  return (
    <StyledLabel htmlFor={id}>
      {text}
      <StyledCheckbox
        name={facetKey}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={updateCheckbox}
      />
      <Checkmark />
    </StyledLabel>
  );
};

Checkbox.defaultProps = {
  facetKey: '',
  value: '',
  text: ''
};

Checkbox.propTypes = {
  facetKey: PropTypes.string,
  value: PropTypes.string,
  text: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Checkbox;
