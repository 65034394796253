import React from 'react';
import PropTypes from 'prop-types';
import Providers from 'components/Providers';
import Filters from 'components/Filters';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 90%;
  max-width: 1800px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 60px;

  @media(max-width:864px) {
    flex-direction: column;
  }
`;

const Results = ({
  filters,
  updateFilters,
  selectedFilters,
  updatePage,
  keyword,
  showFilters,
  hasSearched,
  mapsInfo,
}) => (
  <Container>
    <Filters
      showFilters={showFilters}
      filters={filters}
      updateFilters={updateFilters}
      selectedFilters={selectedFilters}
    />
    <Providers
      updatePage={updatePage}
      keyword={keyword}
      hasSearched={hasSearched}
      mapsInfo={mapsInfo}
    />
  </Container>
);

Results.defaultProps = {
  selectedFilters: {},
  filters: [],
  keyword: ''
};

Results.propTypes = {
  selectedFilters: PropTypes.objectOf(PropTypes.string),

  updatePage: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object),
  updateFilters: PropTypes.func.isRequired,
  keyword: PropTypes.string,
  showFilters: PropTypes.bool.isRequired,
  hasSearched: PropTypes.bool.isRequired,
  mapsInfo: PropTypes.shape({
    searchCenter: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    searchDistance: PropTypes.string
  }).isRequired
};

export default Results;
