import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSelect = styled.select`
  display: block;
  padding: 16px 26px 16px 20px;
  border: 1px solid #666666;
  font-family: "Montserrat", sans-serif;
  border-radius: 6px;
  font-size: 14px;
  line-height: 17px;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  color: #5B5B5B;

  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;

  ::-ms-expand {
    display: none;
  }
  ${(props) => props.styles}
`;

const Select = (props) => {
  const {
    value,
    onChange,
    options,
    styles,
    name
  } = props;

  const handleChange = (e) => {
    const { target: { name: targetName, value: val } } = e;

    onChange(val, targetName);
  };

  return (
    <StyledSelect name={name} styles={styles} value={value} onChange={handleChange}>
      {options.map((option) => {
        const {
          value: val,
          text,
          disabled,
          default: isDefault
        } = option;

        return (
          <option
            key={val}
            value={val}
            disabled={disabled}
            default={isDefault}
          >
            {text}
          </option>
        );
      })}
    </StyledSelect>
  );
};

Select.defaultProps = {
  value: null,
  styles: '',
  name: ''
};

Select.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    default: PropTypes.bool
  })).isRequired,
  styles: PropTypes.string,
  name: PropTypes.string
};

export default Select;
