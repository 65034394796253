import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AccordionContainer = styled.div`
  height: ${({ expanded }) => (expanded ? 'auto' : '4em')};
  max-height: ${({ contentType }) => (contentType === 'Map View' ? '750px' : 'initial')};
  margin-bottom: 1em;
  overflow: hidden;
`;

const AccordionTitleBar = styled.div`
  background-color: #e5f5fc;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 55px;
  align-items: center;
  margin-bottom: 1em;
`;
const AccordionTitle = styled.h2`
  font-size: 1.4rem;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
`;

const OpenIndicator = styled.span`
  width: 23px;
  height: 2px;
  background-color: #84bd00;
  margin: 0 1.5em;

  &::before {
    content: "";
    display: ${({ isOpen }) => (isOpen ? 'block' : 'block')};
    height: 2px;
    transform: ${({ isOpen }) => isOpen && ' rotate(90deg)'};
    width: 23px;
    background-color: #84bd00;
  }
`;

export default function Accordion({ defaultOpen, title, children }) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleOpen = () => setIsOpen(!isOpen);

  return (
    <AccordionContainer expanded={isOpen} contentType={title}>
      <AccordionTitleBar onClick={handleOpen}>
        <OpenIndicator isOpen={isOpen} />
        <AccordionTitle>{title}</AccordionTitle>
      </AccordionTitleBar>
      {children}
    </AccordionContainer>
  );
}

Accordion.propTypes = {
  defaultOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
