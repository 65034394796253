import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Card = styled.div`
  margin-bottom: 20px;
`;

const P = styled.p`
font-size: 16px;
font-weight: 300;
line-height: 24px;
`;

const Name = styled(P)`
  font-weight: 500;
  margin-bottom: 10px;
`;

const Phone = styled(P)`
  margin-top: 10px;
`;

const getCityStateZip = (city, state, zip) => {
  const parts = [];

  if (city) {
    parts.push(city);
  }

  if (state) {
    const stateZip = [];

    if (Array.isArray(state)) {
      stateZip.push(state[0]);
    } else {
      stateZip.push(state);
    }

    if (zip) {
      stateZip.push(zip);
    }

    parts.push(stateZip.join(' '));
  }

  return (<P>{parts.join(', ')}</P>);
};

const Office = (props) => {
  const {
    office: {
      name = '',
      address1 = '',
      addressLine1 = '',
      address2 = '',
      addressLine2 = '',
      city = '',
      state = [],
      zipcode = '',
      zip = '',
      phoneNumber = '',
      phone = ''
    }
  } = props;


  return (
    <Card>
      {name && (<Name>{name}</Name>)}
      {(address1 || addressLine1) && (<P>{(address1 || addressLine1)}</P>)}
      {(address2 || addressLine2) && (<P>{(address2 || addressLine2)}</P>)}
      {getCityStateZip(city, state, zipcode || zip)}
      {(phoneNumber || phone) && (<Phone>{(phoneNumber || phone)}</Phone>)}
    </Card>
  );
};

Office.defaultProps = {};

Office.propTypes = {
  office: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Office;
