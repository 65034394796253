import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from 'components/Form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FacilityAutocomplete } from 'api';

const {
  actions:
  {
    requestFacilityAutocomplete,
    resetFacilityAutocomplete
  }
} = FacilityAutocomplete;

let wrapperRef = null;
let listening = false;

const Div = styled.div`
  position: relative;
  ${(props) => props.styles}
`;

const Results = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  box-sizing: border-box;
  z-index:100;
`;

const Heading = styled.h3`
  text-transform: capitalize;
  color: #666666;
  font-size: 14px;
  font-weigth: 300;
  padding: 13px 18px;
  box-sizing: border-box;

  &:not(:first-child) {
    border-top: 1px solid #cccccc;
    margin-top: 13px;
  }
`;

const Result = styled.div`
  padding: 5px 18px;
  cursor: pointer;
  width: max-content;
  min-width: 100%;
  max-width: calc(100vw * .79);
  overflow-wrap: break-word;
  box-sizing: border-box;
  color: #006298;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  @media(max-width: 1140px) {
    width: 100%;
  }

  em {
    background-color: #feefd7;
    font-weight: 500;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    display: block;
    color: #666666;
  }

  &:hover {
    background-color: #0a6eb0;
    color: #ffffff;

    span {
      color: #ffffff;
    }

    em {
      background-color: #0a6eb0;
    }
  }
`;

const Card = styled.div`
  display: flex;
`;

const KeywordSearchFacilities = (props) => {
  const {
    placeholder,
    value,
    styles,
    setKeyword
  } = props;

  const reset = () => {
    const {
      resetFacilityAutocomplete: makeResetFacilityAutocompleteCall
    } = props;

    makeResetFacilityAutocompleteCall();
  };

  const onBlur = (event) => {
    if (wrapperRef && event.relatedTarget && !wrapperRef.contains(event.relatedTarget)) {
      reset();
    }
  };

  const attemptClose = (event) => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      reset();
    }
  };

  useEffect(() => {
    if (!listening) {
      document.addEventListener('mousedown', attemptClose);
      listening = true;
    }

    const cleanup = () => {
      document.removeEventListener('mousedown', attemptClose);
      listening = false;
    };

    return cleanup;
  });

  const onChange = async (val) => {
    const { length } = val;

    const {
      requestFacilityAutocomplete: makeFacilityAutocompleteCall,
      resetFacilityAutocomplete: makeResetFacilityAutocompleteCall
    } = props;

    if (length > 1) {
      await makeFacilityAutocompleteCall({ terms: val });
    } else {
      makeResetFacilityAutocompleteCall();
    }

    setKeyword(val);
  };

  const hasResults = () => {
    const {
      AutocompleteResults:
      {
        SPECIALTY,
        FACILITIES
      }
    } = props;

    return !!(SPECIALTY.length || FACILITIES.length);
  };

  const selectKeyword = (keyword) => {
    setKeyword(keyword);
    reset();
  };

  const renderResults = (type) => {
    const { AutocompleteResults } = props;
    const results = AutocompleteResults[type];

    const renderResult = (result) => {
      const { practice, hl, specialty } = result;

      return (
        <Card>
          <div>
          <div dangerouslySetInnerHTML={{ __html: hl || practice }} />
            {
              (specialty && Array.isArray(specialty) && specialty.map((s) => (<span key={s}>{s}</span>)))
              || (specialty && (<span key={specialty}>{specialty}</span>))
            }
          </div>
        </Card>
      );
    };

    if (results && results.length) {
      return (
        <>
          <Heading>{type.toLowerCase()}</Heading>
          {results.map((r) => (
            <Result
              onClick={() => selectKeyword(r.practice || r.hl)}
              key={r.practice || r.hl}
            >
              {renderResult(r)}
            </Result>
          ))}
        </>
      );
    }

    return null;
  };

  const setWrapperRef = (node) => {
    wrapperRef = node;
  };

  return (
    <Div styles={styles} ref={setWrapperRef}>
      <Input
        placeholder={placeholder}
        value={value}
        styles="width: 100%"
        onChange={onChange}
        onBlur={onBlur}
      />
      {
        hasResults() && (
          <Results>
            {renderResults('SPECIALTY')}
            {renderResults('FACILITIES')}
          </Results>
        )
      }
    </Div>
  );
};

KeywordSearchFacilities.defaultProps = {
  AutocompleteResults: {},
  placeholder: '',
  value: '',
  styles: ''
};

KeywordSearchFacilities.propTypes = {
  requestFacilityAutocomplete: PropTypes.func.isRequired,
  resetFacilityAutocomplete: PropTypes.func.isRequired,
  AutocompleteResults: PropTypes.objectOf(PropTypes.any),
  setKeyword: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  styles: PropTypes.string
};

const mapStateToProps = (state) => ({
  AutocompleteResults: state.FacilityAutocomplete
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  requestFacilityAutocomplete,
  resetFacilityAutocomplete
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KeywordSearchFacilities);
