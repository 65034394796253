import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "config/store";
import Router from "config/Router";
import * as serviceWorker from "serviceWorker";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import "core-js/stable";
import "regenerator-runtime/runtime";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WPWXB74",
};

TagManager.initialize(tagManagerArgs);

const GlobalStyle = createGlobalStyle`
  ${reset}
  body {
    font-family: 'Montserrat', sans-serif;
  }
  *:focus {
    outline: none;
  }
`;

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <Router />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
