export default class ENV {
  static defaults = {
    API_URL: 'https://ah-provider-search-api-dev.herokuapp.com/api',
    API_VERSION: 'v1',
    API_FORMAT: 'json',
    FILTERS: {
      persona: 80
    }
  };

  static getEnv(name = '') {
    name = name.toUpperCase();

    if (name === 'NODE_ENV') {
      return process.env[name];
    }

    return process.env[`REACT_APP_${name}`] || this.defaults[name];
  }
}
