import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Office from 'components/Office';
import { Select } from 'components/Form';

const selectStyle = `
  width: 100%;
  background-color: whitesmoke;
  border-color: #666666;
`;

const P = styled.p`
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

const Offices = ({ offices }) => {
  const [showOffice, setShowOffice] = useState('0');

  const officeOptions = () => (
    offices.map((office, idx) => ({
      value: idx.toString(),
      text: office.city || office.name.trim() || ''
    }))
  );

  const getOffice = () => {
    if (!offices.length) {
      return null;
    }

    let idx = Number.parseInt(showOffice, 10);
    if (Number.isNaN(idx)) {
      idx = 0;
    }

    return (<Office office={offices[idx]} />);
  };

  const officeSelector = () => {
    if (offices.length < 2) {
      return null;
    }

    return (
      <Select
        styles={selectStyle}
        value={showOffice}
        onChange={setShowOffice}
        options={officeOptions()}
      />
    );
  };

  return (
    <div>
      { offices.length > 1 && <P>More Locations</P>}
      { officeSelector() }
      { getOffice() }
    </div>
  );
};

Offices.defaultProps = {
  offices: []
};

Offices.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.object)
};

export default Offices;
