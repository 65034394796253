import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Facility from 'components/Facility';
import Accordion from 'components/Accordion';
import Pagination from 'components/Pagination';
import styled from 'styled-components';
import Map from 'components/Map';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const StyledProviders = styled.div`
  flex: 1 1 auto;
`;

const RecordCount = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 20px;

  & > em {
    color: #4CA8DC
  }
`;

const getSearchHeading = (keyword) => (
  ` facilities match ${(keyword) ? `'${keyword}'` : 'your search'}.`
);

const Facilities = ({ keyword, hasSearched, mapsInfo, updatePage }) => {
  const [mapShouldRender, setMapShouldRender] = useState(false);
  const location = useLocation();
  const {
    facilities,
    pagination: { recordCount, currentPage, pageCount },
  } = useSelector((state) => state.FacilitySearch);
  const blankSearch =
    location.search === "" ||
    (/location=&/.test(location.search) &&
      /keyword=&/.test(location.search) &&
      !location.search.includes("&specialties="));

  useEffect(() => {
    setMapShouldRender((prev) => {
      if (prev === false && !blankSearch && recordCount) {
        return true;
      } else if ((prev === true && blankSearch) || !recordCount) {
        return false;
      }
      return prev;
    });
  }, [facilities]);

  return (
    <StyledProviders>
      <RecordCount>
        <em>{recordCount}</em>
        <span>{getSearchHeading(keyword)}</span>
      </RecordCount>
      {recordCount > 10 ? (
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          updatePage={updatePage}
        />
      ) : null}
      {hasSearched && mapShouldRender ? (
        <Accordion defaultOpen title="Map View">
          <Map data={facilities} mapsInfo={mapsInfo} mapType="facilities" />
        </Accordion>
      ) : null}

      {recordCount > 0 ? (
        <Accordion defaultOpen title="List View">
          {facilities.map(({ id, ...facility }) => (
            <Facility key={id} facility={facility} />
          ))}
        </Accordion>
      ) : null}
      {recordCount > 10 ? (
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          updatePage={updatePage}
        />
      ) : null}
    </StyledProviders>
  );
};

Facilities.defaultProps = {
  facilities: [],
  pagination: {
    recordCount: 0,
    currentPage: 0,
    pageCount: 0
  },
  keyword: ''
};

Facilities.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.shape({
    recordCount: PropTypes.number,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number
  }),
  updatePage: PropTypes.func.isRequired,
  keyword: PropTypes.string,
  hasSearched: PropTypes.bool.isRequired,
  mapsInfo: PropTypes.shape({
    searchCenter: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    searchDistance: PropTypes.string
  }).isRequired
};

export default Facilities;
