import { uniqBy, cloneDeep } from 'lodash';

const AvailableFilters = [
  {
    label: 'Patient Age Group',
    key: 'ages-accepted',
    type: 'select',
    options: [
      {
        text: 'All patients',
        value: ''
      }
    ]
  },
  {
    label: 'Gender',
    key: 'gender',
    type: 'checkbox',
    defaultOptions: [
      'male',
      'female'
    ],
    disableOverride: true,
    options: [
    ]
  },
  {
    label: 'Accepting New Patients',
    key: 'availability',
    type: 'checkbox',
    defaultOptions: [
      'has:accepting_new_patients'
    ],
    disableOverride: true,
    options: []
  },
  {
    label: 'Languages',
    key: 'language',
    type: 'select',
    options: [
      {
        text: 'All languages',
        value: ''
      }
    ]
  },
  {
    label: 'Specialties',
    key: 'specialties',
    type: 'select',
    disableOverride: false,
    options: [
      {
        text: 'All specialties',
        value: ''
      }
    ]
  },
  {
    label: 'County',
    key: 'county',
    type: 'select',
    disableOverride: false,
    options: [
      {
        text: 'All counties',
        value: ''
      }
    ]
  }
];

const labelOverrides = {
  'Accepts New Patient': 'Yes'
};

const GetFilters = (facets, useDefault) => {
  const filterFn = (availableFilter) => {
    const facetFilterFn = (filter) => (
      availableFilter.defaultOptions.find((val) => val.toLowerCase() === filter.value.toLowerCase())
    );

    let facet = facets[availableFilter.key];

    if (facet) {
      if (availableFilter.defaultOptions && (useDefault || availableFilter.disableOverride)) {
        facet = facet.filter(facetFilterFn);
      }

      facet = facet.filter((option) => option.recordCount === undefined || option.recordCount > 0);

      facet = facet.filter((option) => option.value);

      facet = facet.map((option) => ({
        text: labelOverrides[option.label] || option.label,
        value: option.value,
        enabled: option.enabled || false
      }));

      facet = uniqBy(facet, 'value');

      availableFilter.options = [
        ...availableFilter.options,
        ...facet
      ];
    }

    return availableFilter;
  };

  return cloneDeep(AvailableFilters).map(filterFn);
};

export default GetFilters;
