import { combineReducers } from 'redux';
import * as Api from 'api';
import GlobalSearch from 'api/GlobalSearch';
// eslint-disable-next-line no-undef
var entries = require('object.entries');

const reducers = {};

if (!Object.entries) {
  entries.shim();
}

Object.entries(Api).forEach(([name, api]) => {
  reducers[name] = api.reducer;
});

export default combineReducers({...reducers, GlobalSearch});
