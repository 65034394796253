import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Provider from 'components/Provider';
import Accordion from 'components/Accordion';
import Map from 'components/Map';
import Pagination from 'components/Pagination';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const StyledProviders = styled.div`
  flex: 1 1 auto;
`;

const RecordCount = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 20px;

  & > em {
    color: #4CA8DC
  }
`;

const getSearchHeading = (keyword) => (
  ` providers match ${(keyword) ? `'${keyword}'` : 'your search'}.`
);

const Providers = ({ mapsInfo, hasSearched, keyword, updatePage }) => {
  const [mapShouldRender, setMapShouldRender] = useState(false);
  const location = useLocation();
  const {
    providers,
    pagination: { recordCount, currentPage, pageCount },
  } = useSelector((state) => state.ProviderSearch);
  const facetTests = [
    /ages-accepted=/,
    /availability=/,
    /county=/,
    /gender=/,
    /county=/,
    /specialties=/,
  ];

  const blankSearch =
    location.search === "" ||
    (/location=&/.test(location.search) &&
      /keyword=&/.test(location.search) &&
      !facetTests.filter((item) => item.test(location.search)).length);

  useEffect(() => {
    setMapShouldRender((prev) => {
      if (prev === false && !blankSearch && recordCount) {
        return true;
      } else if ((prev === true && blankSearch) || !recordCount) {
        return false;
      }
      return prev;
    });
    // eslint-disable-next-line
  }, [recordCount]);

  return (
    <StyledProviders>
      <RecordCount>
        <em>{recordCount}</em>
        <span>{getSearchHeading(keyword)}</span>
      </RecordCount>
      {recordCount > 10 ? (
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          updatePage={updatePage}
        />
      ) : null}
      {hasSearched && mapShouldRender ? (
        <Accordion defaultOpen title="Map View">
          <Map data={providers} mapsInfo={mapsInfo} mapType="providers" />
        </Accordion>
      ) : null}

      {recordCount > 0 ? (
        <Accordion defaultOpen title="List View">
          {providers.map(({ id, ...provider }) => (
            <Provider key={id} provider={provider} />
          ))}
        </Accordion>
      ) : null}
      {recordCount > 10 ? (
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          updatePage={updatePage}
        />
      ) : null}
    </StyledProviders>
  );
};

Providers.defaultProps = {
  providers: [],
  pagination: {
    recordCount: 0,
    currentPage: 0,
    pageCount: 0
  },
  keyword: ''
};

Providers.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.shape({
    recordCount: PropTypes.number,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number
  }),
  updatePage: PropTypes.func.isRequired,
  keyword: PropTypes.string,
  hasSearched: PropTypes.bool.isRequired,
  mapsInfo: PropTypes.shape({
    searchCenter: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    searchDistance: PropTypes.string
  }).isRequired
};

export default Providers;
