import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import styled from 'styled-components';

const StyledInput = styled.input`
  padding: 16px 20px;
  border: 1px solid #666666;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  color: #5B5B5B;
  &::placeholder {
    color: #5B5B5B;
    font-family: "Montserrat", sans-serif;
  }
  width: 100%;
`;

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
`;

const AutocompleteContainer = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  box-sizing: border-box;
  z-index: 100;
  padding: 10px 0;
  ${({ hasSuggestions }) => !hasSuggestions && 'display: none;'}
`;

const AutocompleteSuggestion = styled.div`
  padding: 5px 18px;
  cursor: pointer;
  width: max-content;
  min-width: 100%;
  max-width: calc(100vw * .79);
  overflow-wrap: break-word;
  box-sizing: border-box;
  color: #006298;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  @media(max-width: 1140px) {
    width: 100%;
  }

  &:hover {
    background-color: #0a6eb0;
    color: #ffffff;
  }
`;

const PlaceSearch = ({ value, onChange }) => {
  const handleSelect = async (addr) => {
    try {
      const results = await geocodeByAddress(addr);
      await getLatLng(results[0]);
      onChange(addr);
    } catch (error) {
      onChange('');
    }
  };

  const autoSelectSuggestion = (suggestions) => {
    if (suggestions.length) {
      handleSelect(suggestions[0].description);
    }
  };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={handleSelect}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading
      }) => (
        <StyledContainer>
          <StyledInput
            {...getInputProps({
              placeholder: 'Address / City / Zip',
              className: 'location-search-input',
              onBlur: () => { autoSelectSuggestion(suggestions); }
            })}
          />
          <AutocompleteContainer hasSuggestions={suggestions.length}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => (
              <div key={suggestion.placeId}>
                <AutocompleteSuggestion {...getSuggestionItemProps(suggestion)}>
                  {suggestion.description}
                </AutocompleteSuggestion>
              </div>
            ))}
          </AutocompleteContainer>
        </StyledContainer>
      )}
    </PlacesAutocomplete>
  );
};

PlaceSearch.defaultProps = {
  value: ''
};

PlaceSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default PlaceSearch;
