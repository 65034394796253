import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'config/api';

const initialState = {
  meta: {
    fetching: false
  },
  SPECIALTY: [],
  CONDITIONS: [],
  TREATMENTS: [],
  PROVIDERS: [],
  KEYWORDS: []
};

class ProviderAutocomplete extends Api {
  REQUEST_PROVIDER_AUTOCOMPLETE = 'REQUEST_PROVIDER_AUTOCOMPLETE';
  RECEIVE_PROVIDER_AUTOCOMPLETE = 'RECEIVE_PROVIDER_AUTOCOMPLETE';
  RESET_PROVIDER_AUTOCOMPLETE = 'RESET_PROVIDER_AUTOCOMPLETE';

  get actions() {
    const {
      REQUEST_PROVIDER_AUTOCOMPLETE,
      RECEIVE_PROVIDER_AUTOCOMPLETE,
      RESET_PROVIDER_AUTOCOMPLETE
    } = this;

    function requestProviderAutocomplete(params) {
      return {
        type: REQUEST_PROVIDER_AUTOCOMPLETE,
        params
      };
    }

    function receiveProviderAutocomplete(data) {
      return {
        type: RECEIVE_PROVIDER_AUTOCOMPLETE,
        data
      };
    }

    function resetProviderAutocomplete() {
      return {
        type: RESET_PROVIDER_AUTOCOMPLETE
      };
    }

    return {
      requestProviderAutocomplete,
      receiveProviderAutocomplete,
      resetProviderAutocomplete
    };
  }

  get reducer() {
    const {
      REQUEST_PROVIDER_AUTOCOMPLETE,
      RECEIVE_PROVIDER_AUTOCOMPLETE,
      RESET_PROVIDER_AUTOCOMPLETE
    } = this;

    return (state = initialState, { type, data }) => {
      switch (type) {
        case REQUEST_PROVIDER_AUTOCOMPLETE:
          return {
            ...state,
            meta: {
              fetching: true
            }
          };
        case RECEIVE_PROVIDER_AUTOCOMPLETE:
          return {
            meta: {
              fetching: false
            },
            ...data
          };
        case RESET_PROVIDER_AUTOCOMPLETE:
          return {
            ...initialState
          };
        default:
          return state;
      }
    };
  }

  get saga() {
    const {
      get,
      actions: { receiveProviderAutocomplete },
      REQUEST_PROVIDER_AUTOCOMPLETE
    } = this;

    const providerAutocompleteApi = async (params) => (get({ path: 'provider/autocomplete', params }));

    function* fetchProviderAutocomplete(action) {
      const { params = {} } = action;
      try {
        const { data } = yield call(providerAutocompleteApi, params);
        yield put(receiveProviderAutocomplete(data));
      } catch (e) {
        // Do Nothing
      }
    }

    return function* providerAutocomplete() {
      yield takeLatest(REQUEST_PROVIDER_AUTOCOMPLETE, fetchProviderAutocomplete);
    };
  }
}

export default new ProviderAutocomplete();
