import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'config/api';

const initialState = {
  meta: {
    fetching: false
  },
  pagination: {
    recordCount: 0,
    currentPage: 0,
    pageCount: 0
  },
  providers: [],
  facet: {}
};

class ProviderSearch extends Api {
  REQUEST_PROVIDER_SEARCH = 'REQUEST_PROVIDER_SEARCH';
  RECEIVE_PROVIDER_SEARCH = 'RECEIVE_PROVIDER_SEARCH';

  get actions() {
    const { REQUEST_PROVIDER_SEARCH, RECEIVE_PROVIDER_SEARCH } = this;

    function requestProviderSearch(params) {
      return {
        type: REQUEST_PROVIDER_SEARCH,
        params
      };
    }

    function receiveProviderSearch(data) {
      return {
        type: RECEIVE_PROVIDER_SEARCH,
        data
      };
    }

    return {
      requestProviderSearch,
      receiveProviderSearch
    };
  }

  get reducer() {
    const { REQUEST_PROVIDER_SEARCH, RECEIVE_PROVIDER_SEARCH } = this;

    return (state = initialState, { type, data }) => {
      switch (type) {
        case REQUEST_PROVIDER_SEARCH:
          return {
            ...state,
            meta: {
              fetching: true
            }
          };
        case RECEIVE_PROVIDER_SEARCH:
          return {
            meta: {
              fetching: false
            },
            ...data
          };
        default:
          return state;
      }
    };
  }

  get saga() {
    const {
      get,
      actions: { receiveProviderSearch },
      REQUEST_PROVIDER_SEARCH
    } = this;

    const providerSearchApi = async (params) => (get({ path: 'provider/search', params }));

    function* fetchProviderSearch(action) {
      const { params = {} } = action;
      try {
        const { data } = yield call(providerSearchApi, params);
        yield put(receiveProviderSearch(data));
      } catch (e) {
        // Do Nothing
      }
    }

    return function* providerSearch() {
      yield takeLatest(REQUEST_PROVIDER_SEARCH, fetchProviderSearch);
    };
  }
}

export default new ProviderSearch();
