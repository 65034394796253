import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import Home from 'components/Home';
import HomeFacilities from 'components/HomeFacilities';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/facilities" component={HomeFacilities} />
      </Switch>
    </Router>
  );
}
