import React from 'react';
import styled from 'styled-components';

const NavBar = styled.div`
  background-color: #006298;
  width: 100vw;
  height: 100px;
  color: white;
  padding-left: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  @media(max-width: 640px) {
    padding-left: 0;
    justify-content: center;
  }
`;

const Logo = styled.img`
  height: 60px;
`;

const Header = () => (
  <NavBar>
    <Logo src="/adventhealth_provider_network_main_logo_white.png" alt="AdventHealth" />
  </NavBar>
);

export default Header;
