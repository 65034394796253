import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'config/api';

const initialState = {
  meta: {
    fetching: false
  },
  pagination: {
    recordCount: 0,
    currentPage: 0,
    pageCount: 0
  },
  facilities: [],
  facet: {}
};

class FacilitySearch extends Api {
  REQUEST_FACILITY_SEARCH = 'REQUEST_FACILITY_SEARCH';
  RECEIVE_FACILITY_SEARCH = 'RECEIVE_FACILITY_SEARCH';

  get actions() {
    const { REQUEST_FACILITY_SEARCH, RECEIVE_FACILITY_SEARCH } = this;

    function requestFacilitySearch(params) {
      return {
        type: REQUEST_FACILITY_SEARCH,
        params
      };
    }

    function receiveFacilitySearch(data) {
      return {
        type: RECEIVE_FACILITY_SEARCH,
        data
      };
    }

    return {
      requestFacilitySearch,
      receiveFacilitySearch
    };
  }

  get reducer() {
    const { REQUEST_FACILITY_SEARCH, RECEIVE_FACILITY_SEARCH } = this;

    return (state = initialState, { type, data }) => {
      switch (type) {
        case REQUEST_FACILITY_SEARCH:
          return {
            ...state,
            meta: {
              fetching: true
            }
          };
        case RECEIVE_FACILITY_SEARCH:
          return {
            meta: {
              fetching: false
            },
            ...data
          };
        default:
          return state;
      }
    };
  }

  get saga() {
    const {
      get,
      actions: { receiveFacilitySearch },
      REQUEST_FACILITY_SEARCH
    } = this;

    const FacilitySearchApi = async (params) => (get({ path: 'facility/search', params }));

    function* fetchFacilitySearch(action) {
      const { params = {} } = action;
      try {
        const { data } = yield call(FacilitySearchApi, params);
        yield put(receiveFacilitySearch(data));
      } catch (e) {
        // Do Nothing
      }
    }

    return function* FacilitySearch() {
      yield takeLatest(REQUEST_FACILITY_SEARCH, fetchFacilitySearch);
    };
  }
}

export default new FacilitySearch();
