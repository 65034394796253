import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";
import styled from "styled-components";
import PropTypes from "prop-types";
import { getDistance } from "geolib";
import { useSelector } from "react-redux";

const StyledInfoWindow = styled.div`
  font-family: "Montserrat", sans-serif;
`;

const CardTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: #006298;
  @media (max-width: 600px) {
    font-size: 0.9rem;
  } ;
`;
const Distance = styled.p`
  font-size: 0.75rem;
`;

const P = styled.p`
  font-size: 16px;
  line-height: 1.3;
  @media (max-width: 600px) {
    font-size: 0.85rem;
    line-height: 1.3;
  }

  & a {
    color: #808080;
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

const Practice = styled(P)`
  font-weight: 500;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    font-size: 0.85rem;
  } ;
`;

const Phone = styled(P)`
  margin-top: 10px;
  @media (max-width: 600px) {
    font-size: 0.9rem;
  } ;
`;

const containerStyle = {
  height: "50vw",
  maxHeight: "680px",
  width: "100%",
  overflow: "hidden",
};

const icon = {
  path: "M12.5.4C6.8.4 0 3.1 0 12.9 0 19.4 3.9 26.4 11.8 34c8.3-8 12.5-15.1 12.5-21.1 0-9.1-6-12.5-11.8-12.5zm-.3 17.3c-2.8 0-5-2.3-5-5s2.3-5 5-5 5 2.3 5 5-2.2 5-5 5z",
  fillColor: "rgb(231, 78, 66)",
  fillOpacity: 1.0,
  strokeWeight: 0.5,
  scale: 1.4,
  origin: new window.google.maps.Point(0, 0),
  anchor: new window.google.maps.Point(10, 30),
};

const MarkerCard = ({ provider, center, mapType }) => {
  const { displayName, distanceFromQuery } = provider;
  const { name, addressLine1, addressLine2, city, state, zip, phone } =
    provider.addresses;
  let distance = distanceFromQuery.toFixed(2);

  // Check if results are from default start point that
  // is set when location is reset but specialty is not.
  // Conditionally renders distance from search text.
  if (center.lng === -82.299444) {
    distance = null;
  }
  const searchLink = `https://www.google.com/maps/search/?api=1&query=${name}+${addressLine1}+${addressLine2}+${city}+${state}`;

  return (
    <>
      <StyledInfoWindow>
        {distance && <Distance>{`About ${distance} miles away`}</Distance>}
        {mapType === "providers" ? (
          <>
            <CardTitle>{displayName}</CardTitle>
            <Practice>{name}</Practice>
          </>
        ) : (
          <CardTitle>{name}</CardTitle>
        )}

        <P>
          <a href={searchLink} target="_blank" rel="noopener noreferrer">
            {`${addressLine1}`}
            <br />
            {addressLine2 && (
              <>
                {addressLine2}
                <br />
              </>
            )}
            {`${city}, ${state} ${zip}`}
          </a>
        </P>
        <Phone>
          <a href={`tel:${phone}`}>{phone}</a>
        </Phone>
      </StyledInfoWindow>
    </>
  );
};

export default function Map({ data, mapsInfo, mapType }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCVlWM2-iryGM6iTTAB55fJlH5MzRrzbJ0",
  });

  const [map, setMap] = useState(null);
  const [prevData, setPrevData] = useState([]);
  const [mapInstanceCount, setMapInstanceCount] = useState(0);
  const [activeMarker, setActiveMarker] = useState(-1);
  const [markerOpen, setMarkerOpen] = useState(false);
  const [results, setResults] = useState([]);
  const globalSearchCenter = useSelector((state) => state.GlobalSearch.coords);
  const { searchDistance, county } = mapsInfo;
  const onLoad = useCallback((thisMap) => setMap(thisMap), []);

  // Prevents marker from remaining open when results page changes
  useEffect(() => {
    setActiveMarker(-1);
  }, [results]);

  useEffect(() => {
    setPrevData(data);
    if (data !== prevData || mapInstanceCount === 0) {
      if (data.length !== 0) {
        const pinsToPlace = [];
        const locations = [];
        data.forEach((provider) => {
          for (let i = 0; i < provider.addresses.length; i += 1) {
            const lat =
              +provider.addresses[i].latitude + Math.random() * 0.00007;
            const lng =
              +provider.addresses[i].longitude + Math.random() * 0.00007;
            const coordinates = { lat, lng };

            /* calculate distance from search center for instances such as: Dr. X has three locations where 2 fall within search parameters and 1 does not. */

            let distanceFromQuery =
              getDistance(
                {
                  latitude: globalSearchCenter.lat,
                  longitude: globalSearchCenter.lng,
                },
                { latitude: lat, longitude: lng }
              ) * 0.000621371;

            if (
              county &&
              county.toLowerCase() ===
                provider.addresses[i].county.toLowerCase()
            ) {
              locations.push(coordinates);
              pinsToPlace.push({
                ...provider,
                addresses: provider.addresses[i],
                distanceFromQuery,
                coordinates,
              });
            } else if (!county && distanceFromQuery <= searchDistance) {
              locations.push(coordinates);
              pinsToPlace.push({
                ...provider,
                addresses: provider.addresses[i],
                distanceFromQuery,
                coordinates,
              });
            }
          }
        });

        setResults(pinsToPlace);

        /* Checks to make sure the number of pins to place
       is at least as great as the number of items in the
       results set before rendering map. Prevents clustering issue
       caused by an unnecessary re-render */

        if (map && locations.length >= data.length) {
          setMapInstanceCount(mapInstanceCount + 1);
          const bounds = new window.google.maps.LatLngBounds();
          locations.forEach((location, idx) => {
            bounds.extend(location);
            if (idx === locations.length - 1) {
              bounds.extend(globalSearchCenter);
            }
          });
          map.fitBounds(bounds);
        }
      }
    }
    // eslint-disable-next-line
  }, [map, data, mapsInfo]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      setMarkerOpen(!markerOpen);
      return;
    }
    setMarkerOpen(true);
    setActiveMarker(marker);
  };

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      onClick={() => setMarkerOpen(false)}
      mapContainerStyle={containerStyle}
      options={{ streetViewControl: false, mapTypeControl: false, maxZoom: 20 }}
    >
      <MarkerClusterer
        gridSize={8}
        enableRetinaIcons
        calculator={(markers) => {
          const total = markers.length;
          const count = total.toString();
          return {
            text: count,
            index: Math.min(total, 2),
          };
        }}
      >
        {(clusterer) =>
          results.map((provider, index) => (
            <Marker
              clusterer={clusterer}
              maxZoom={18}
              key={`${provider.id}${provider.addresses.id}`}
              position={provider.coordinates}
              onClick={() => handleActiveMarker(index)}
              icon={icon}
            >
              {activeMarker === index && markerOpen ? (
                <InfoWindow
                  position={provider.coordinates}
                  onCloseClick={() => setActiveMarker(null)}
                >
                  <MarkerCard
                    provider={provider}
                    center={globalSearchCenter}
                    mapType={mapType}
                  />
                </InfoWindow>
              ) : null}
            </Marker>
          ))
        }
      </MarkerClusterer>
    </GoogleMap>
  ) : (
    <></>
  );
}

Map.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  mapsInfo: PropTypes.shape({
    searchCenter: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    searchDistance: PropTypes.string,
    county: PropTypes.string,
  }).isRequired,
  mapType: PropTypes.oneOf(["facilities", "providers"]).isRequired,
};

MarkerCard.propTypes = {
  provider: PropTypes.objectOf(PropTypes.any).isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  mapType: PropTypes.oneOf(["facilities", "providers"]).isRequired,
};
