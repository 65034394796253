import { call, put, takeLatest } from 'redux-saga/effects';
import Api from 'config/api';

const initialState = {
  meta: {
    fetching: false
  },
  SPECIALTY: [],
  FACILITIES: []
};

class FacilityAutocomplete extends Api {
  REQUEST_FACILITY_AUTOCOMPLETE = 'REQUEST_FACILITY_AUTOCOMPLETE';
  RECEIVE_FACILITY_AUTOCOMPLETE = 'RECEIVE_FACILITY_AUTOCOMPLETE';
  RESET_FACILITY_AUTOCOMPLETE = 'RESET_FACILITY_AUTOCOMPLETE';

  get actions() {
    const {
      REQUEST_FACILITY_AUTOCOMPLETE,
      RECEIVE_FACILITY_AUTOCOMPLETE,
      RESET_FACILITY_AUTOCOMPLETE
    } = this;

    function requestFacilityAutocomplete(params) {
      return {
        type: REQUEST_FACILITY_AUTOCOMPLETE,
        params
      };
    }

    function receiveFacilityAutocomplete(data) {
      return {
        type: RECEIVE_FACILITY_AUTOCOMPLETE,
        data
      };
    }

    function resetFacilityAutocomplete() {
      return {
        type: RESET_FACILITY_AUTOCOMPLETE
      };
    }

    return {
      requestFacilityAutocomplete,
      receiveFacilityAutocomplete,
      resetFacilityAutocomplete
    };
  }

  get reducer() {
    const {
      REQUEST_FACILITY_AUTOCOMPLETE,
      RECEIVE_FACILITY_AUTOCOMPLETE,
      RESET_FACILITY_AUTOCOMPLETE
    } = this;

    return (state = initialState, { type, data }) => {
      switch (type) {
        case REQUEST_FACILITY_AUTOCOMPLETE:
          return {
            ...state,
            meta: {
              fetching: true
            }
          };
        case RECEIVE_FACILITY_AUTOCOMPLETE:
          return {
            meta: {
              fetching: false
            },
            ...data
          };
        case RESET_FACILITY_AUTOCOMPLETE:
          return {
            ...initialState
          };
        default:
          return state;
      }
    };
  }

  get saga() {
    const {
      get,
      actions: { receiveFacilityAutocomplete },
      REQUEST_FACILITY_AUTOCOMPLETE
    } = this;

    const FacilityAutocompleteApi = async (params) => (get({ path: 'facility/autocomplete', params }));

    function* fetchFacilityAutocomplete(action) {
      const { params = {} } = action;
      try {
        const { data } = yield call(FacilityAutocompleteApi, params);
        yield put(receiveFacilityAutocomplete(data));
      } catch (e) {
        // Do Nothing
      }
    }

    return function* FacilityAutocomplete() {
      yield takeLatest(REQUEST_FACILITY_AUTOCOMPLETE, fetchFacilityAutocomplete);
    };
  }
}

export default new FacilityAutocomplete();
