/* eslint class-methods-use-this: "off" */
import env from 'config/env';
import axios from 'axios';

var entries = require('object.entries');

const getAPI = (path, queries = {}) => {
  if (path.indexOf('/') === 0) {
    path = path.substr(1);
  }

  if (path.lastIndexOf('/') + 1 === path.length) {
    path = path.slice(0, -1);
  }

  if (!Object.entries) {
    entries.shim();
  }

  queries.format = env.getEnv('API_FORMAT');

  const filter = (entry) => [undefined, null, ''].indexOf(entry[1]) === -1;

  const queryParams = Object.entries(queries).filter(filter).map((entry) => `${entry[0]}=${entry[1]}`).join('&');

  return `${env.getEnv('API_URL')}/${env.getEnv('API_VERSION')}/${path}/?${queryParams}`;
};

export default class Api {
  get actions() {
    throw new Error('get actions() must be implemented on inherited classes');
  }

  get reducer() {
    throw new Error('get reducer() must be implemented on inherited classes');
  }

  get saga() {
    throw new Error('get saga() must me implemented on inherited classes');
  }

  async get({ path = '', params = {}, ...rest }) {
    const requestURI = getAPI(path, params);
    const resp = await axios.get(requestURI, rest);
    return resp;
  }
}
