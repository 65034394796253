const GLOBAL_SEARCH_UPDATE = "GLOBAL_SEARCH_UPDATE";
const GLOBAL_SEARCH_RESET = "GLOBAL_SEARCH_RESET";

const defaultState = {
  coords: {
    lat: 28.5675,
    lng: -82.299444,
  },
};

export const updateGlobalSearch = (coords) => ({
  type: GLOBAL_SEARCH_UPDATE,
  payload: { lat: coords.lat, lng: coords.lng },
});
export const resetGlobalSearch = () => ({
  type: GLOBAL_SEARCH_RESET,
  payload: { coords: { lat: 28.5675, lng: -82.299444 } },
});

const GlobalSearch = (state = defaultState, action) => {
  switch (action.type) {
    case GLOBAL_SEARCH_UPDATE:
      return {
        coords: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
      };
    case GLOBAL_SEARCH_RESET:
      return defaultState;

    default:
      return state;
  }
};

export default GlobalSearch;
