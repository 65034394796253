import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
  padding: 16px 20px;
  border: 1px solid #666666;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  color: #5B5B5B;
  &::placeholder {
    color: #5B5B5B;
    font-family: "Montserrat", sans-serif;
  }
  ${(props) => props.styles}
`;

const Input = (props) => {
  const {
    type,
    placeholder,
    value,
    onChange,
    onBlur,
    styles,
    name
  } = props;

  const inputChange = (e) => {
    const { target: { name: targetName, value: val } } = e;

    onChange(val, targetName);
  };

  return (
    <StyledInput
      name={name}
      styles={styles}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={inputChange}
      onBlur={onBlur}
    />
  );
};

Input.defaultProps = {
  type: 'text',
  name: '',
  placeholder: '',
  value: '',
  styles: '',
  onBlur: () => {}
};

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'password']),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  styles: PropTypes.string
};

export default Input;
