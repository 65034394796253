import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Select } from "components/Form";
import KeywordSearch from "components/KeywordSearchFacilities";
import PlaceSearch from "components/PlaceSearch";
import Toggle from "components/Toggle";

const SearchBar = styled.div`
  background-color: #e5f5fc;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;

  @media (max-width: 640px) {
    ${({ showSearch }) => !showSearch && "display:none"}
  }
`;

const FormElements = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 90%;
  max-width: 1300px;
  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 1140px) {
    flex-direction: column;
    width: 90%;
    & > *:not(:last-child) {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 640px) {
    & > *:not(:last-child) {
      margin-bottom: 0px;
    }
  }
`;

const FormElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  ${({ elementType }) =>
    elementType === "within-select" &&
    `
  flex-direction: row;
  align-items: flex-end;
  width: 40%;
`}

  @media(max-width: 1140px) {
    width: 100%;
  }

  @media (max-width: 640px) {
    margin-top: 10px;
  }
`;

const FormElementLabel = styled.div`
  margin: 10px 0;
  ${({ labelType }) =>
    labelType === "within-select" &&
    `
  margin: 0 10px 0 0;
  align-self: center;
`}

  ${({ labelType }) =>
    labelType === "network-select" &&
    `
      @media(max-width: 1140px) {
        padding: 1em 0 0 0;
  }
`}
`;

const FormGroup = styled.div`
  display: flex;
  align-items: flex-end;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
  width: 100%;

  @media (max-width: 640px) {
    width: 100%;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    flex-direction: column;
  }
`;

const largeInputStyle = `
  width: 100%;

  @media(max-width:1140px) {
    width: 100%;
  }
`;

const smallInputStyle = `
  width: 100%;

  @media(max-width:1140px) {
    width: 90%;
  }

  @media(max-width:640px) {
    width: 90%;
  }
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  width: 90%;
  max-width: 1300px;
  justify-content: flex-end;

  @media (max-width: 1140px) {
    width: 100%;
    margin-top: 30px;
    justify-content: center;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SearchButton = styled.button`
  color: #ffffff;
  border: 0;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background-color: #006298;
  border-radius: ${({ hasSearched }) => (hasSearched ? "6px 0 0 6px" : "6px")};
  cursor: pointer;

  &:hover {
    background-color: #0079bc;
  }

  @media (max-width: 1140px) {
    width: 45%;
  }

  @media (max-width: 640px) {
    width: 90%;
    margin-bottom: 10px;
    border-radius: 6px;
  }
`;

const ResetButton = styled(SearchButton)`
  background-color: #8e8e8e;
  border-radius: 0 6px 6px 0;
  ${({ hasSearched }) => !hasSearched && "display: none"}

  &:hover {
    background-color: #5a5a5a;
  }
`;

const RequestLocationButton = styled.button`
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
  color: #006298;
  background: none;
  border: none;
  position: absolute;
  bottom: -25px;
  cursor: pointer;
  transition: color linear 0.25s;
  &:hover,
  &:focus {
    color: #0083cb;
    text-decoration: none;
  }
`;

const SearchFacilities = (props) => {
  const {
    search,
    reset,
    updateState,
    params: { keyword, location, distance, cin },
    showSearch,
    hasSearched,
  } = props;

  const distanceOptions = [
    { value: "5", text: "5 miles" },
    { value: "10", text: "10 miles" },
    { value: "15", text: "15 miles" },
    { value: "20", text: "20 miles" },
    { value: "50", text: "50 miles" },
    { value: "100", text: "100 miles" },
  ];

  const cinOptions = [
    { value: "AHPN-WF,AHPN-CF", text: "Choose Network", disabled: true },
    { value: "AHPN-WF", text: "West Florida" },
    { value: "AHPN-CF", text: "Central Florida" },
  ];

  const setKeyword = (val) => updateState("keyword", val);
  const setLocation = (val) => updateState("location", val);
  const setDistance = (val) => updateState("distance", val);
  const setCin = (val) => updateState("cin", val);

  function requestLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: -27000,
    };
    const success = (pos) => {
      const latString = pos.coords.latitude.toString().slice(0, 8);
      const lngString = pos.coords.longitude.toString().slice(0, 8);
      setLocation(`${latString}, ${lngString}`);
    };
    const error = (err) => {
      // eslint-disable-next-line no-console
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  return (
    <form>
      <SearchBar showSearch={showSearch}>
        <Toggle />

        <FormElements>
          <FormElementContainer>
            <FormElementLabel>Search by practice or specialty</FormElementLabel>
            <KeywordSearch
              placeholder="Practice or specialty"
              value={keyword}
              setKeyword={setKeyword}
              styles={largeInputStyle}
            />
          </FormElementContainer>
          <FormGroup>
            <FormElementContainer>
              <FormElementLabel>Located near</FormElementLabel>
              <PlaceSearch value={location} onChange={setLocation} />
              <RequestLocationButton type="button" onClick={requestLocation}>
                Use my location
              </RequestLocationButton>
            </FormElementContainer>
            <FormElementContainer elementType="within-select">
              <FormElementLabel labelType="within-select">
                Within
              </FormElementLabel>

              <Select
                styles={smallInputStyle}
                value={distance}
                onChange={setDistance}
                options={distanceOptions}
              />
            </FormElementContainer>
          </FormGroup>
          <FormElementContainer>
            <FormElementLabel labelType="network-select">
              Select a network
            </FormElementLabel>

            <Select
              styles={largeInputStyle}
              value={cin}
              onChange={setCin}
              options={cinOptions}
            />
          </FormElementContainer>
        </FormElements>

        <Actions>
          <SearchButton
            type="submit"
            onClick={search}
            hasSearched={hasSearched}
          >
            Search
          </SearchButton>
          <ResetButton type="button" onClick={reset} hasSearched={hasSearched}>
            Reset
          </ResetButton>
        </Actions>
      </SearchBar>
    </form>
  );
};

SearchFacilities.defaultProps = {
  params: {
    keyword: "",
    location: "",
    distance: "",
    cin: "",
  },
};

SearchFacilities.propTypes = {
  search: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  params: PropTypes.shape({
    keyword: PropTypes.string,
    location: PropTypes.string,
    distance: PropTypes.string,
  }),
  showSearch: PropTypes.bool.isRequired,
  hasSearched: PropTypes.bool.isRequired,
};

export default SearchFacilities;
