import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducer from 'config/reducer';
import sagas from 'config/sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const enhancers = [];

// eslint-disable-next-line no-underscore-dangle
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}
const composedEnhancers = compose(applyMiddleware(sagaMiddleware), ...enhancers);

// mount it on the Store
const store = createStore(
  reducer,
  composedEnhancers
);

// then run the saga
sagas.forEach((saga) => {
  sagaMiddleware.run(saga);
});

export default store;
