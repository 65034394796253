import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

const ToggleWrapper = styled.div`
  display: flex;
  margin: 0 auto 2em auto;

  > a {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    display: block;
    flex: 0 1 auto;
    padding: 1em 2.25em;
    text-decoration: none;
    color: black;
  
    &:first-child {
      border-radius: 6px 0px 0px 6px;
    }
  
    &:last-child {
      border-radius: 0px 6px 6px 0px;
      border-left: none;
    }

    &.active {
      background: #0079bc;
      color: white;
      border: none;
    }
  }
`;

const Toggle = (props) => {

  return (
    <ToggleWrapper>
      <NavLink exact to="/" activeClassName="active">
        Find Provider
      </NavLink>
      <NavLink exact to="/facilities" activeClassName="active">
        Find Facility
      </NavLink>
    </ToggleWrapper>
  );
};

export default Toggle;
